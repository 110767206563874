import React, { Component } from 'react';

export default class Header extends Component {
    render() {
        return (
            <div>
                <h1 align="center">Cardano Paper Wallet</h1>
            </div>
        );
    }
}